<nav class="navBar" v-if="isLoggedIn">
  <form class="form-inline">
    <input
      class="form-control mr-sm-2"
      type="search"
      placeholder="¿Qué deseas buscar?"
      aria-label="Search"
    />
    <button
      class="btn btn-outline-success my-2 my-sm-0"
      type="submit"
      @click="search"
    >
      Buscar
    </button>
  </form>
  <div class="navbar-end">
    <button
      class="btn btn-outline-dark my-2 my-sm-0"
      type="submit"
      @click="handleSignOut"
    >
      Salir
    </button>
  </div>
</nav>
