import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { initializeApp } from "firebase/app";
import axios from "axios";
import store from "./store/store";

// Configuraciones para conectarse a Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    /* TODO: check if cookie is set or expired here? */
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const isCookieSet = document.cookie
        .split(";")
        .some((cookie) => cookie.trim().startsWith("access_token="));

      if (!isCookieSet) {
        /* TODO: Delete localStorage */
        router.push("/");
      }
    }

    return Promise.reject(error);
  }
);

// Iniciamos las configuraciones de Firebase
initializeApp(firebaseConfig);

//Creamos el App
const app = createApp(App);

// Activamos el router
app.use(router, axios, store);

// Aplicacion
app.mount("#app");
