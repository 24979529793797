<template src="./SideBar.html"></template>
<style lang="scss" scoped src="./SideBar.scss"></style>
<script>
import { checkRole } from "@/services/users";

export default {
  name: "SideBar",
  data() {
    return {
      rol: "",
      nombreCompleto: String,
      correo: String,
      isExpanded: false,
    };
  },
  async created() {
    this.getUserInfo();
    await this.checkRole();
  },
  methods: {
    getUserInfo() {
      this.nombreCompleto = localStorage
        ? localStorage.getItem("first_name") +
          " " +
          localStorage.getItem("last_name")
        : "";
      this.correo = localStorage?.getItem("email") || "";
    },
    async checkRole() {
      try {
        const response = await checkRole();
        this.rol = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    goHome() {
      this.$router.push("/home");
    },
    goUsuarios() {
      this.$router.push("/usuarios");
    },
    goCursos() {
      this.$router.push("/cursos");
    },
    goMatricula() {
      this.$router.push("/matricula");
    },
    toggleMenu() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
