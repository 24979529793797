import axios from "axios";

const usersRoute = "/users";

export const editMe = (formData) =>
  axios.put(`${usersRoute}/edit_me`, formData, { withCredentials: true });

export const editUser = (formData) =>
  axios.put(`${usersRoute}/edit_user`, formData, { withCredentials: true });

export const getUsers = () =>
  axios.get(`${usersRoute}`, { withCredentials: true });

export const checkRole = () =>
  axios.get(`${usersRoute}/check-role`, {
    withCredentials: true,
  });

export const createUser = (formData) =>
  axios.post(`${usersRoute}/create-user`, formData, {
    withCredentials: true,
  });

export const getMyCourses = () =>
  axios.get(`${usersRoute}/get-my-courses`, {
    withCredentials: true,
  });
