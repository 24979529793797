<template>
  <div class="app">
    <SideBar v-if="isAuthenticated" />
    <div class="content-view">
      <NavBar v-if="isAuthenticated" />
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import SideBar from "@/components/sidebar/SideBar.vue";
import NavBar from "@/components/navbar/NavBar.vue";

const isAuthenticated = ref(false);

onMounted(async () => {
  isAuthenticated.value = !!localStorage.getItem("user_id");
});
</script>

<style lang="scss" src="./styles/app.scss"></style>
