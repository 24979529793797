<template src="./NavBar.html"></template>
<style lang="scss" src="./Navbar.scss"></style>
<script>
export default {
  name: "NavBar",
  data() {
    return { isLoggedIn: false };
  },
  async mounted() {
    this.isLoggedIn = localStorage.getItem("user_id") !== null;
  },
  methods: {
    handleSignOut() {
      localStorage.clear();
      this.$router.go("/");
    },
    search() {},
  },
};
</script>
